import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/MailOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Link';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'black',
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
    backgroundColor:'#3c3c3c',
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
    alignItems: 'center',
    padding:'50px',
    borderRadius:'12px'
	},
	links: {
		margin: theme.spacing(20, 15),
		padding: '10px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
  },
  right: {
    color: 'white',
		display: 'flex',
		background: 'black',
		fontFamily: 'Montserrat',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight:'70px'
  },
	left: {
		color: 'white',
		display: 'flex',
		background: 'black',
		fontFamily: 'Montserrat',
    alignItems: 'center',
    justifyContent: 'center',
    padding:'0 100px',
    marginTop: '-210px',
	},
	heading: {
		fontWeight: '700',
		fontSize: '76px',
    textAlign: 'left',
    letterSpacing:'1.18px',
    fontStretch:'condensed',
    lineHeight: '68.4px'
  },
  smaller: {
    marginTop: '40px',
    textAlign: 'left',
    fontSize: '24px',
    
  },
  textField: {
    backgroundColor:'white',
    fontFamily: 'Montserrat',
    color:'#3c3c3c',
    overFlow:'hidden',
    borderRadius: '5px',
  },
	submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#00b7d1',
    fontWeight:'bold',
	}
}));

export default function Donate() {
	const classes = useStyles();

	const [ data, setData ] = useState({});
	const [ done, setDone ] = useState(false);

	const updateData = (key) => (e) => {
		console.log(e.target.value);
		let tmp = data;
		tmp[key] = e.target.value;
		setData(tmp);
	};

	const sendForm = (e) => {
		e.preventDefault();
		fetch('/api/sendmsg', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(function(response) {
				return response.json();
			})
			.then(function(data) {
				setDone(true);
			});
		return false;
	};
	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={8} className={classes.left}>
				<Grid container>
					<Grid item xs style={{ display: 'flex', justifyContent: 'flex-Start', alignItems: 'center', width:'300px', flexGrow:0 }}>
						<Link href="https://signs365.com" target="_blank" variant="body2">
							<img src="/signslogo.png" width="300" alt="signs365" />
						</Link>
					</Grid>
					<Grid item>
						<Link href="https://sunsh1n3.com" target="_blank" variant="body2" >
							<img src="/sunsh1n3logo.gif" width="550" alt="sunsh1n3" style={{marginTop:'46px', marginLeft:'-15px'}} />
						</Link>
					</Grid>
          <Grid item>
            <Typography className={classes.heading}>We are in need of elastic and polycarbonate.</Typography>
            <Typography className={classes.smaller}>
								If you would like to help our efforts to fight corona virus please fill out the form.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={8} md={4} className={classes.right}>
				<div className={classes.paper}>
					{done === false ? (
						<span>
							<form className={classes.form} onSubmit={sendForm}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="name"
									label="Name"
									name="name"
                  autoComplete="name"
                  className={classes.textField}
									onChange={updateData('name')}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
                  type="email"
                  classes={{ root: 'MuiTextField', focused: {color:'white'} }}
                  className={classes.textField}
									onChange={updateData('email')}
									autoComplete="email"
								/>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id="phone"
									label="Phone Number"
                  name="phone"
                  className={classes.textField}
									onChange={updateData('phone')}
									autoComplete="phone"
								/>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="message"
									label="Message"
									name="message"
                  multiline
                  className={classes.textField}
									onChange={updateData('msg')}
									rows="4"
									autoComplete="phone"
								/>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
								>
									Send
								</Button>
							</form>
						</span>
					) : (
						<Typography component="h1" variant="h5">
							Thank you! We will contact you soon.
						</Typography>
					)}
				</div>
			</Grid>
		</Grid>
	);
}
