import React from 'react';
import './App.css';
import Form from './form';





function App() {
  return (
    <div className="App">
      <Form />
      <div className="footer">
        <span>
          Download 3d Printer files 
        </span>
        <span>
          <a href="/6hole3dprint.stl" target="_blank" download="6hole3dprint.stl" variant="body2"><img src="/button.png" /> Face Shield - STL</a>
        </span>
        <span>
          <a href="/6hole3dprint.dxf" target="_blank" download="6hole3dprint.dxf" variant="body2"><img src="/button.png" /> Face Shield Cut File - dxf</a>
        </span> 
        <span>
          <a href="https://3dverkstan.se/protective-visor/" target="_blank">source</a> 
        </span>
        <span>
          <a href="https://www.youtube.com/watch?v=CHDMdyN5Jjs" target="_blank">video</a> 
        </span>
        {/* <span style={{marginLeft:'50px'}}>
        Download Cut files for face shields  
        </span>
        <span>
          <a href="/face_shield.zip" target="_blank" download="face_shield.zip" variant="body2"><img src="/button.png" /> Face Shield - Various Formats</a> 
        </span> */}
      </div>
    </div>
  );
}

export default App;
